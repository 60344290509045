import React from 'react';
import { ExpectBird, ExpectNoise, MobileExpectBird } from '../../../../assets/img';
import { TextBox } from './TextBox';
import { textContent } from '../../../../constants';
import useExpectAnimation from '../../../../hooks/useExpect';

import './Expect.scss';

const MemoizedExpectBird = React.memo(ExpectBird);

const Expect: React.FC = () => {
    const { handleCard, textBoxRefs, textBoxMobRefs, highlightRef } = useExpectAnimation();

    return (
        <div className="expect-container">
            <div className="expect-left-container">
                <MemoizedExpectBird className="expect-bird" />
                <ExpectNoise className="expect-noise" />
            </div>
            <div className="expect-right-container">
                <h2 className="expect-heading">
                    What you can{' '}
                    <span className="expect-highlight" ref={highlightRef}>
                        expect
                    </span>
                </h2>
                {textContent.map((item, index) => (
                    <TextBox
                        key={index}
                        title={item.title}
                        text={item.text}
                        ref={el => (textBoxRefs.current[index] = el)}
                        onClick={() => {
                            handleCard(index);
                        }}
                    />
                ))}
            </div>

            {/* Mobile version */}
            <div className="expect-container-mob">
                <h2 className="expect-heading-mob">
                    What you can <span className="expect-highlight-mob">expect</span>
                </h2>
                <div className="expect-left-container-mob">
                    <MobileExpectBird className="expect-bird-mob" />
                    <ExpectNoise className="expect-noise-mob" />
                </div>
                <div className="expect-right-container-mob">
                    {textContent.map((item, index) => (
                        <TextBox
                            key={index}
                            title={item.title}
                            text={item.text}
                            ref={el => (textBoxMobRefs.current[index] = el)}
                            onClick={() => {
                                handleCard(index);
                            }}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Expect;
