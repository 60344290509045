import React, { useRef } from 'react';
import './Innovation.scss';
import { useGSAP } from '@gsap/react';
import { InnovationBird, MobileInnovationBird } from '../../../../assets/img';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';

const Innovation = () => {
    const spanRef = useRef(null);
    const desRef = useRef(null);

    useGSAP(() => {
        const tl = gsap.timeline();
        const mm = gsap.matchMedia();

        mm.add('(min-width:450px)', () => {
            gsap.set('.innovation-animation-mob', { display: 'none' });
            gsap.set('.innovation-animation', { display: 'block' });

            tl.fromTo('.innovation-title', { y: 100, opacity: 0 }, { y: 0, opacity: 1 })
                .fromTo(spanRef.current, { backgroundPosition: '0%' }, { backgroundPosition: '-100%' })
                .fromTo(desRef.current, { y: 100, opacity: 0 }, { y: 0, opacity: 1 });
            ScrollTrigger.create({
                animation: tl,
                trigger: '.innovation-container',
                toggleActions: 'play reset reverse none',
                start: 'top top',
                end: 'bottom top',
                scrub: true,
                pin: '.innovation-animation',
            });
        });

        mm.add('(max-width:450px)', () => {
            gsap.set('.innovation-animation', { display: 'none' });
            gsap.set('.innovation-animation-mob', { display: 'block' });

            tl.fromTo('.innovation-title-mob', { y: 100, opacity: 0 }, { y: 0, opacity: 1 })
                .fromTo('.innovation-span-mob', { backgroundPosition: '0%' }, { backgroundPosition: '-100%' })
                .fromTo('.innovation-desc-mob', { y: 100, opacity: 0 }, { y: 0, opacity: 1 });
            ScrollTrigger.create({
                animation: tl,
                trigger: '.innovation-container',
                toggleActions: 'play reset reverse none',
                start: 'top top',
                end: 'bottom top',
                scrub: true,
                pin: '.innovation-animation-mob',
            });
        });
    }, []);

    return (
        <div className="innovation-container">
            <div className="innovation-animation">
                <div className="innovation-elements">
                    <div className="innovation-title">
                        Elevate your <br /> leadership with <br />
                        <span className="innovation-span" ref={spanRef}>
                            ETH’s AI innovation
                        </span>
                    </div>
                    <div className="innovation-bird">
                        <InnovationBird />
                    </div>
                    <div className="innovation-desc" ref={desRef}>
                        The Leadership Companion is more than just a tool—it embodies ETH’s commitment to empowering leaders
                        with advanced, personalized support. At its core are custom fine-tuned models, developed at ETH using
                        cutting-edge AI technology, ensuring that every interaction seamlessly adapts to your needs.
                    </div>
                </div>
            </div>

            {/* Mobile version */}
            <div className="innovation-animation-mob">
                <div className="innovation-elements-mob">
                    <div className="innovation-title-mob">
                        Elevate your leadership <br /> with
                        <span className="innovation-span-mob">ETH’s AI innovation</span>
                    </div>
                    <div className="innovation-desc-mob">
                        The Leadership Companion is more than just a tool—it embodies ETH’s commitment to empowering leaders
                        with advanced, personalized support. At its core are custom fine-tuned models, developed at ETH using
                        cutting-edge AI technology, ensuring that every interaction seamlessly adapts to your needs.
                    </div>
                    <div className="innovation-bird-mob">
                        <MobileInnovationBird />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Innovation;
