import React from 'react';
import TabletImage from '../../../../assets/img/tablet.webp';
import { CompanionBird, MobileCompanionBird, PlayButton, TabletBirdNoise } from '../../../../assets/img';
import useCompanionAnimation from '../../../../hooks/useCompanion';

import './Companion.scss';

const MemoizedCompanionBird = React.memo(CompanionBird);

const Companion = () => {
    const { companionFirstRef, companionSecondRef, companionThirdRef, companionSpanRef, companionTabletRef } =
        useCompanionAnimation();

    return (
        <div className="companion-container">
            <TabletBirdNoise className="companion-bird-noise-mob" />
            <h2 className="companion-title">
                <div className="companion-subtitle-first" ref={companionFirstRef}>
                    Meet the{' '}
                    <span className="tablet-bird">
                        <CompanionBird className="companion-bird" />
                        <TabletBirdNoise className="companion-bird-noise" />
                    </span>
                    <span className="companion-span" ref={companionSpanRef}>
                        Leadership Companion:
                    </span>
                    <br />
                </div>
                <div className="companion-subtitle-second" ref={companionSecondRef}>
                    your AI-powered companion for
                </div>
                <div className="companion-subtitle-second" ref={companionThirdRef}>
                    confident, effective leadership.
                </div>
            </h2>
            <img src={TabletImage} ref={companionTabletRef} alt="tablet" className="companion-tablet" />

            {/* Mobile version */}
            <h2 className="companion-title-mob">
                <div className="companion-subtitle-first-mob">
                    Meet the <br />
                    <span className="companion-span-mob">Leadership Companion:</span>
                    <br />
                </div>
                <div className="companion-subtitle-second-mob">
                    <MobileCompanionBird className="companion-bird-mob" />
                    your AI-powered
                </div>
                <div className="companion-subtitle-second-mob">
                    companion for confident,
                    <br />
                    effective leadership.
                </div>
            </h2>
            <div className="tab-container">
                <img src={TabletImage} alt="tablet" className="companion-tablet-mob" />
                <div className="watch-video">
                    <PlayButton />
                    Watch video
                </div>
            </div>
        </div>
    );
};

export default Companion;
