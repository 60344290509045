import { useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useGSAP } from "@gsap/react";

const useApproachAnimation = () => {
    const approachRightRef = useRef<HTMLDivElement | null>(null);
    const approachLeftRef = useRef<HTMLDivElement | null>(null);

    useGSAP(() => {
        const tl = gsap.timeline();
        const mm = gsap.matchMedia();

        mm.add('(min-width:450px)', () => {
            gsap.set(
                '.approach-container-mob',
                { display: 'none' }
            )
            gsap.set(
                approachLeftRef.current,
                { display: 'flex' }
            )
            gsap.set(
                '.approach-left-container-mob',
                { display: 'none' }
            )
            gsap.set(
                approachRightRef.current,
                { display: 'flex' }
            )
            gsap.set(
                '.approach-image-container-mob',
                { display: 'none' }
            )
            tl.fromTo(
                approachLeftRef.current,
                { y: 100, opacity: 0 },
                { y: 0, opacity: 1, duration: 1 }
            ).fromTo(
                approachRightRef.current,
                { y: 100, opacity: 0 },
                { y: 0, opacity: 1, duration: 1 }
            ).fromTo(
                ".approach-highlight",
                { backgroundPosition: "0%" },
                { backgroundPosition: "-100%" }
            );
        })

        mm.add('(max-width:450px)', () => {
            gsap.set(
                approachLeftRef.current,
                { display: 'none' }
            )
            gsap.set(
                '.approach-left-container-mob',
                { display: 'flex' }
            )
            gsap.set(
                approachRightRef.current,
                { display: 'none' }
            )
            gsap.set(
                '.approach-image-container-mob',
                { display: 'block' }
            )
            gsap.set(
                '.approach-container-mob',
                { display: 'flex' }
            )

            gsap.set(
                '.approach-container',
                { alignItems: 'start' }
            )

            tl.fromTo(
                '.approach-left-container-mob',
                { y: 100, opacity: 0 },
                { y: 0, opacity: 1, duration: 1 }
            ).fromTo(
                ".approach-highlight-mob",
                { backgroundPosition: "0%" },
                { backgroundPosition: "-100%" }
            );
        })

        ScrollTrigger.create({
            animation: tl,
            trigger: '.approach-container',
            toggleActions: "play pause reverse none",
            start: "top top",
            end: "bottom top",
            scrub: true,
            pin: true,
        });
    }, []);

    return {
        approachRightRef,
        approachLeftRef
    };
};

export default useApproachAnimation;
