import React, { useRef } from 'react';
import {
    AfternoonColor,
    FirstStep,
    FourthStep,
    Seconds1,
    Seconds2,
    Seconds3,
    Seconds4,
    SecondsBackground,
    SecondsLine,
    SecondStep,
    ThirdStep,
} from '../../../../assets/img';

import './Seconds.scss';
import { useGSAP } from '@gsap/react';
import gsap, { ScrollTrigger } from 'gsap/all';
import Ready from '../Ready';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';

const cardsData = [
    {
        image: <FirstStep className="seconds-card-screen" />,
        icon: <Seconds1 />,
        title: 'Start',
        desc: 'Log in using your existing ETH account or create a new one.',
    },
    {
        image: <SecondStep className="seconds-card-screen" />,
        icon: <Seconds2 />,
        title: 'Engage',
        desc: 'Interact with your AI Companion through chat or voice.',
    },
    {
        image: <ThirdStep className="seconds-card-screen" />,
        icon: <Seconds3 />,
        title: 'Reflect & grow',
        desc: 'Receive practical support and develop your own solutions.',
    },
    {
        image: <FourthStep className="seconds-card-screen" />,
        icon: <Seconds4 />,
        title: 'Transform your leadership',
        desc: 'Make sustainable progress and celebrate success.',
    },
];

const Seconds = () => {
    const spanRef = useRef(null);
    const spanReadyRef = useRef(null);
    const spanMobReadyRef = useRef(null);

    useGSAP(() => {
        const tl = gsap.timeline();
        const mm = gsap.matchMedia();
        mm.add('(min-width:450px)', () => {
            gsap.set('.seconds-background', { display: 'block' });
            gsap.set('.seconds-line', { display: 'block' });
            gsap.set('.afternoon-color', { display: 'block' });
            gsap.set('.seconds-content', { display: 'block' });
            gsap.set('.ready-container', { display: 'block' });

            gsap.set('.seconds-background-mob', { display: 'none' });
            gsap.set('.seconds-line-mob', { display: 'none' });
            gsap.set('.afternoon-color-mob', { display: 'none' });
            gsap.set('.seconds-content-mob', { display: 'none' });
            gsap.set('.ready-container-mob', { display: 'none' });

            tl.fromTo('.seconds-background', { opacity: 0 }, { opacity: 1 }, 0)
                .fromTo(spanRef.current, { backgroundPosition: '0%' }, { backgroundPosition: '-100%' })
                .fromTo('.seconds-title', { opacity: 0 }, { opacity: 1 }, 0)
                .fromTo('.seconds-line', { opacity: 0 }, { opacity: 1 }, 0)
                .fromTo('.cards-container', { x: '100%', y: '100%', opacity: 0 }, { x: '-50%', y: '-28%', opacity: 1 }, 0)
                .fromTo('.seconds-card-0', { x: '100%', opacity: 0 }, { x: '0%', opacity: 1 })
                .fromTo('.seconds-card-1', { x: '100%', opacity: 0 }, { x: '0%', opacity: 1 })
                .fromTo('.seconds-card-2', { x: '100%', opacity: 0 }, { x: '0%', opacity: 1 })
                .fromTo('.seconds-card-3', { x: '100%', opacity: 0 }, { x: '0%', opacity: 1 })
                .to('.seconds-title', { y: '-100%', opacity: 0, margin: 0 })
                .to('.cards-container', { y: '-100%', opacity: 0, margin: 0 })
                .to('.seconds-line', { opacity: 0 })
                .fromTo('.ready-container', { y: '100%' }, { y: '0%' })
                .fromTo('.afternoon-color', { y: '-100%' }, { y: '0' })
                .fromTo('.ready-title', { y: '100%', opacity: 0 }, { y: '0%', opacity: 1 })
                .fromTo(spanReadyRef.current, { backgroundPosition: '0%' }, { backgroundPosition: '-100%' })
                .fromTo('.ready-input', { y: '100%', opacity: 0 }, { y: '0%', opacity: 1 })
                .fromTo('.ready-desc', { y: '100%', opacity: 0 }, { y: '0%', opacity: 1 })
                .fromTo('.ready-cta', { y: '100%', opacity: 0 }, { y: '0%', opacity: 1 });
            ScrollTrigger.create({
                animation: tl,
                trigger: '.seconds-container',
                toggleActions: 'play reset reverse none',
                start: 'top top',
                end: '+=200%',
                scrub: true,
                pin: true,
            });
        });

        mm.add('(max-width:450px)', () => {
            gsap.set('.seconds-background', { display: 'none' });
            gsap.set('.seconds-line', { display: 'none' });
            gsap.set('.afternoon-color', { display: 'none' });
            gsap.set('.seconds-content', { display: 'none' });
            gsap.set('.ready-container', { display: 'none' });
            gsap.set('.cards-container', { display: 'none' });
            gsap.set('.seconds-title', { display: 'none' });

            gsap.set('.seconds-background-mob', { display: 'block' });
            gsap.set('.seconds-line-mob', { display: 'block' });
            gsap.set('.afternoon-color-mob', { display: 'block' });
            gsap.set('.seconds-content-mob', { display: 'block' });
            gsap.set('.ready-container-mob', { display: 'block' });

            tl.fromTo('.seconds-background-mob', { opacity: 0 }, { opacity: 1 }, 0)
                .fromTo('.seconds-span-mob', { backgroundPosition: '0%' }, { backgroundPosition: '-100%' })
                .fromTo('.seconds-title-mob', { opacity: 0 }, { opacity: 1 }, 0)
                .fromTo('.seconds-line-mob', { opacity: 0 }, { opacity: 1 }, 0)
                .fromTo('.cards-container-mob', { x: '100%', opacity: 0 }, { x: '0%', opacity: 1 }, 0)
                .fromTo('.seconds-card-1-mob', { x: '100%', opacity: 0 }, { x: '0%', opacity: 1 }, 0)
                .fromTo('.seconds-card-2-mob', { x: '100%', opacity: 0 }, { x: '0%', opacity: 1 })
                .fromTo('.seconds-card-3-mob', { x: '100%', opacity: 0 }, { x: '0%', opacity: 1 })
                .fromTo('.seconds-content-mob', { y: '0', opacity: 1 }, { y: '-100%', opacity: 0, margin: 0 })
                .to('.seconds-line-mob', { opacity: 0 })
                .fromTo('.ready-container-mob', { y: '100%' }, { y: '-68%' })
                .fromTo('.afternoon-color-mob', { y: '-100%' }, { y: '0' })
                .fromTo('.ready-title-mob', { y: '100%', opacity: 0 }, { y: '0%', opacity: 1 })
                .fromTo(spanMobReadyRef.current, { backgroundPosition: '0%' }, { backgroundPosition: '-100%' })
                .fromTo('.ready-input-mob', { y: '100%', opacity: 0 }, { y: '0%', opacity: 1 })
                .fromTo('.ready-desc-mob', { y: '100%', opacity: 0 }, { y: '0%', opacity: 1 })
                .fromTo('.ready-cta-mob', { y: '100%', opacity: 0 }, { y: '0%', opacity: 1 });
            ScrollTrigger.create({
                animation: tl,
                trigger: '.seconds-container',
                toggleActions: 'play reset reverse none',
                start: 'top top',
                end: '+=200%',
                scrub: true,
                pin: true,
            });
        });
    }, []);

    return (
        <div className="seconds-container">
            <div className="seconds-background" />
            <div className="seconds-line" />

            <div className="afternoon-color" />
            <div className="cards-container">
                {cardsData.map((card, index) => (
                    <div className={`seconds-card-${index} card-container`} key={index}>
                        <div className="seconds-card-background" />
                        {card.image}
                        <div className="seconds-card-details">
                            {card.icon}
                            <div className="seconds-desc-container">
                                <div className="seconds-card-title">{card.title}</div>
                                <div className="seconds-card-desc">{card.desc}</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="seconds-title">
                Get started in{' '}
                <span className="seconds-span" ref={spanRef}>
                    seconds
                </span>
            </div>
            <div className="ready-container">
                <Ready spanRef={spanReadyRef} />
            </div>

            {/* Mobile Version */}
            <SecondsBackground className="seconds-background-mob" />
            <SecondsLine className="seconds-line-mob" />
            <AfternoonColor className="afternoon-color-mob" />
            <div className="seconds-content-mob">
                <div className="seconds-title-mob">
                    Get started in <span className="seconds-span-mob">seconds</span>
                </div>
                <div className="cards-container-mob">
                    <Swiper pagination={true} modules={[Pagination]}>
                        {cardsData.map((card, index) => (
                            <SwiperSlide key={index}>
                                <div className={`seconds-card-${index + 1}-mob`} key={index}>
                                    <div className="seconds-card-background-mob" />
                                    <div className="seconds-card-screen-mob">{card.image}</div>
                                    <div className="seconds-card-details-mob">
                                        {card.icon}
                                        <div className="seconds-desc-container-mob">
                                            <div className="seconds-card-title-mob">{card.title}</div>
                                            <div className="seconds-card-desc-mob">{card.desc}</div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
            <div className="ready-container-mob">
                <Ready spanRef={spanMobReadyRef} />
            </div>
        </div>
    );
};

export default Seconds;
