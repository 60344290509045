import React, { useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';

import '../Faqs.scss';

const QnaItem = ({ question, answer, index, openQna, handleClick, totalLength }) => (
    <div className={`qna-single-question ${index === totalLength - 1 ? 'pb-50' : ''}`} key={index}>
        <div className="qna-question" onClick={() => handleClick(index)}>
            {index === openQna ? <FaMinus style={{ minWidth: 20 }} /> : <FaPlus size={20} style={{ minWidth: 20 }} />}
            <div>{question}</div>
        </div>
        <div className={`qna-answer ${index === openQna ? 'open-question' : 'close-question'}`}>{answer}</div>
    </div>
);

export const QnaList = ({ qnaData }) => {
    const [openQna, setOpenQna] = useState(null);

    const handleClick = index => {
        setOpenQna(openQna === index ? null : index);
    };

    return (
        <>
            {qnaData && qnaData.length > 0 ? (
                qnaData.map((item, index) => (
                    <QnaItem
                        key={index}
                        index={index}
                        totalLength={qnaData.length}
                        question={item.question}
                        answer={item.description}
                        openQna={openQna}
                        handleClick={handleClick}
                    />
                ))
            ) : (
                <div className="no-data-found">No data found</div>
            )}
        </>
    );
};

export const qnaData = [
    {
        question: 'How does the Leadership Companion compare to other development methods?',
        description: 'The Leadership Companion offers a more personalized approach...',
    },
    {
        question: 'What makes this method unique?',
        description: 'Its focus on self-awareness and practical skills...',
    },
    {
        question: 'How does the Leadership Companion compare to other development methods?',
        description: 'The Leadership Companion offers a more personalized approach...',
    },
    {
        question: 'What makes this method unique?',
        description: 'Its focus on self-awareness and practical skills...',
    },
    {
        question: 'How does the Leadership Companion compare to other development methods?',
        description: 'The Leadership Companion offers a more personalized approach...',
    },
    {
        question: 'What makes this method unique?',
        description: 'Its focus on self-awareness and practical skills...',
    },
    {
        question: 'How does the Leadership Companion compare to other development methods?',
        description: 'The Leadership Companion offers a more personalized approach...',
    },
    {
        question: 'What makes this method unique?',
        description: 'Its focus on self-awareness and practical skills...',
    },
    {
        question: 'How does the Leadership Companion compare to other development methods?',
        description: 'The Leadership Companion offers a more personalized approach...',
    },
    {
        question: 'What makes this method unique?',
        description: 'Its focus on self-awareness and practical skills...',
    },
];
