import React, { useCallback, useEffect, useRef, useState } from 'react';
import './Session.scss';
import SessionHeader from './SessionHeader';
import { getSession, getSessions } from '../../utilities/apiService';
import { Session as SessionType } from '../../types';
import { useParams } from 'react-router-dom';
import Message from './Message';
import TextInput from '../../components/TextInput';
import useWebSocket from '../../hooks/useWebSocket';
import { history, useAppSelector } from '../../store';
import { sessionActions } from '../../actions';
import { useDispatch } from 'react-redux';
import ProgressBar from '../../components/ProgressBar';
import { MilestoneId } from '../../types';

const Session = () => {
    const dispatch = useDispatch();
    const messagesEndRef = useRef(null); // ToDo: replace with scroll the container
    const [initialMessageSent, setInitialMessageSent] = useState(false);
    const [currentSession, setCurrentSession] = useState<SessionType>(null);
    const [isMilestoneSetting, setIsMilestoneSetting] = useState(false);
    const [milestoneType, setMilestoneType] = useState<MilestoneId>('problem');
    const [milestoneText, setMilestoneText] = useState('');
    const activeSession = useAppSelector(state => state.session.activeSession);
    const auth = useAppSelector(state => state.auth);
    let initialMessage = useAppSelector(state => state.session.message);
    const isLocalhost =
        window.location.hostname === 'localhost' ||
        window.location.hostname === '127.0.0.1';

    const wsUrl = isLocalhost
        ? `ws://localhost:8000/ws/`  // WebSocket for local development
        : `wss://${window.location.hostname}/ws/`;  // Secure WebSocket for production

    const params = useParams();
    const {
        messages,
        sendMessage,
        objective,
        problem,
        sources,
        links,
        resetChat,
        chatId,
        switchSession,
        isAuthenticated,
        isProcessing,
        goal,
        solution,
        action,
        milestone,
        updateMilestone,
    } = useWebSocket(wsUrl, auth.accessToken);


    const handleSession = useCallback((hash: string) => {
        try {
            getSession(hash).then((session) => {
                if (session) {
                    sessionActions.setActiveSession(session)(dispatch);
                    sessionActions.setSources(sources)(dispatch);
                    setCurrentSession(session);
                }
            });
        } catch (error) {
            console.error('Error fetching session:', error);
        }
    }, [dispatch, setCurrentSession, sources]);

    useEffect(() => {
        if (params.hash) {
            handleSession(params.hash);
        }
    }, [params.hash]);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({behavior: 'smooth'});
    }, [messages]);

    useEffect(() => {
        if (!initialMessageSent && initialMessage && isAuthenticated) {
            localStorage.setItem('activeSessionId', '');
            sessionActions.setActiveSession(null)(dispatch);
            setCurrentSession(null);
            setInitialMessageSent(true);
            sendMessage(initialMessage, false, true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialMessageSent, initialMessage, dispatch, isAuthenticated]);

    useEffect(() => {
        if (!initialMessage && localStorage.getItem('activeSessionId') === '') {
            setCurrentSession(null);
            resetChat();
        }
    }, [localStorage.getItem('activeSessionId'), initialMessage]);

    useEffect(() => {
        if (!currentSession && chatId && messages.length == 2 && messages[1].finished) {
            try {
                getSessions()
                .then((updatedSessions) => {
                    sessionActions.loadSessions(updatedSessions)(dispatch);
                    if (updatedSessions && updatedSessions.length > 0) {
                        const active = updatedSessions.find(s => s.hash_id === chatId);

                        if (active) {
                            sessionActions.setActiveSession(active)(dispatch);
                            localStorage.setItem('activeSessionId', active.hash_id);
                            setCurrentSession(active);
                            history.push(`/session/${active.hash_id}`);
                        }
                    }
                })
                .catch(() => {
                    console.log('Error occurred when fetching sessions');
                });
            } catch (error) {
                console.error('Error updating active session:', error);
            }
        }
    }, [chatId, messages]);

    useEffect(() => {
        if (milestone && milestone.milestoneType && milestone.isSetting) {
            setMilestoneType(milestone.milestoneType);
            setMilestoneText(milestone.milestoneText);
            setIsMilestoneSetting(true);
        } else if (milestone && !milestone.isSetting && activeSession?.hash_id) {
            setIsMilestoneSetting(false);
            handleSession(activeSession?.hash_id);
        } else if (milestone && milestone.error && milestone.error.length > 0) {
            console.error('Error updating milestone', milestone.error);
        }
    }, [activeSession?.hash_id, handleSession, milestone]);

    return (
        <div className="session-wrapper position-relative">
            <SessionHeader sessionHash={params.hash} title={currentSession?.messages[0]?.content}/>
            <div className="session-bar-container">
                <ProgressBar activeSession={currentSession}/>
                <div className="session-container container position-relative z-1">
                    {messages && messages.map((
                        message,
                        idx) => {
                        return <Message key={idx} message={message}/>;
                    })}
                    <div ref={messagesEndRef}/>
                </div>
            </div>
            
            <div className="container session-input-container z-0">
                <TextInput
                    isDisabled={!isAuthenticated}
                    isProcessing={isProcessing}
                    onSubmit={(val) => {
                        sendMessage(val, false);
                    }}
                    isSetMilestone={isMilestoneSetting}
                    setIsSetMilestone={setIsMilestoneSetting}
                    milestoneType={milestoneType}
                    milestoneText={milestoneText}
                    updateMilestone={updateMilestone}
                />
            </div>
        </div>
    );
};

export default Session;
