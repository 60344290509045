import { ScrollTrigger } from "gsap/all";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

const useLeadershipAnimation = (
) => {
    useGSAP(() => {
        gsap.registerPlugin(ScrollTrigger);
        const mm = gsap.matchMedia();

        const tl = gsap.timeline();

        mm.add('(min-width:450px)', () => {
            tl.to('.features-title', { display: 'block' }).to('.features-title-mobile', { display: 'none' }).fromTo(
                '.feature-subtitle-first',
                { y: 100, opacity: 0 },
                { y: 0, opacity: 1, duration: 1 }
            )
                .fromTo(
                    '.feature-subtitle-second',
                    { y: 110, opacity: 0 },
                    { y: 0, opacity: 1, duration: 1 }
                )
                .fromTo(
                    '.features-span',
                    { backgroundPosition: "0%" },
                    { backgroundPosition: "-100%" }
                );
        })

        mm.add('(max-width:450px)', () => {
            gsap.to(
                ".features",
                {
                    backgroundImage: 'url(./mobile-feature.webp)'
                }
            )

            tl.to('.features-title', { display: 'none' })
                .to('.features-title-mobile', { display: 'block' }).fromTo(
                    '.feature-subtitle-first-mob',
                    { y: 100, opacity: 0 },
                    { y: 0, opacity: 1, duration: 1 }
                )
                .fromTo(
                    '.feature-subtitle-second-mob',
                    { y: 110, opacity: 0 },
                    { y: 0, opacity: 1, duration: 1 }
                )
                .fromTo(
                    '.features-span-leadership',
                    { backgroundPosition: "0%" },
                    { backgroundPosition: "-100%" }
                )
                .fromTo(
                    '.features-span-challenges',
                    { backgroundPosition: "0%" },
                    { backgroundPosition: "-100%" }
                )
        })

        ScrollTrigger.create({
            animation: tl,
            trigger: ".leadership-container",
            toggleActions: "play reset reverse none",
            start: "top top",
            end: "bottom top",
            pin: true,
            scrub: true,
        });
    }, []);
};

export default useLeadershipAnimation;
