import React, { useEffect } from 'react';
import './Dashboard.scss';
import Icon from '../../components/Icon';
import Text from '../../components/Text';
import TextInput from '../../components/TextInput';
import { history, useAppSelector } from '../../store';
import { sessionActions } from '../../actions/';
import { useDispatch } from 'react-redux';
import HeaderIllustration from '../../components/HeaderIllustration';
import { getDaycycle } from '../../common';


const Dashboard = () => {
    const dispatch = useDispatch();
    const userState = useAppSelector(state => state.auth.user);
    const dayCycle = getDaycycle();

    const submitMessage = message => {
        sessionActions.sendMessage(message)(dispatch);
        history.push('/session');
    };

    useEffect(() => {
        sessionActions.setActiveSession(null)(dispatch);
    }, [dispatch]);

    return (
        <div className="dashboard-wrapper pb-5">
            <HeaderIllustration/>
            <div className="origami-bird-bg position-absolute z-2"/>
            <div className="container z-3 position-relative">
                <Icon type="origami" className="position-relative"/>
                <Text tag="h1" className="eth-space-top-kilo position-relative" label={`eth.dashboard.title1.${dayCycle}`}
                          message={`Good ${dayCycle}, ${userState.name.split(' ')[0]}.`}/>
                <Text tag="h1" label="eth.dashboard.title2" className="position-relative"
                      message="Let’s make an impact today."/>
                <Text className="eth-space-top-centi eth-space-bottom-hecto dashboard-text" md left
                      label="eth.dashboard.text"
                      message="To get started, ask a question. Whether you're facing a challenge or seeking to develop your leadership, I'll guide you in reflecting and uncovering your own insights, offering practical suggestions to support your growth."/>

                <TextInput onSubmit={submitMessage}/>

                <Text className="eth-space-top-giga eth-space-bottom-kilo" h4 left
                      label="eth.dashboard.benefits.title"
                      message="What you can expect"/>
                <div className="row benefits-row">
                    <div className="col">
                        <div className="benefits-icon-wrapper position-absolute">
                            <Icon type="lock" className="benefits-icon"/>
                        </div>
                        <Text h5 left label="eth.dashboard.benefits.benefit1.title"
                              message="Absolute confidentiality"/>
                        <Text left className="eth-space-top-nano" label="eth.dashboard.benefits.benefit1.text"
                              message="Your conversations are encrypted and never shared. We prioritize your privacy and data security."/>
                    </div>
                    <div className="col">
                        <div className="benefits-icon-wrapper position-absolute">
                            <Icon type="timer" className="benefits-icon"/>
                        </div>
                        <Text h5 left label="eth.dashboard.benefits.benefit1.title"
                              message="Absolute confidentiality"/>
                        <Text left className="eth-space-top-nano" label="eth.dashboard.benefits.benefit1.text"
                              message="Your conversations are encrypted and never shared. We prioritize your privacy and data security."/>
                    </div>
                    <div className="col">
                        <div className="benefits-icon-wrapper position-absolute">
                            <Icon type="visibility" className="benefits-icon"/>
                        </div>
                        <Text h5 left label="eth.dashboard.benefits.benefit1.title"
                              message="Absolute confidentiality"/>
                        <Text left className="eth-space-top-nano" label="eth.dashboard.benefits.benefit1.text"
                              message="Your conversations are encrypted and never shared. We prioritize your privacy and data security."/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
