import { useState, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useGSAP } from "@gsap/react";

const useExpectAnimation = () => {
    const [isAnimationInProgress, setIsAnimationInProgress] = useState(true);
    const [openCard, setOpenCard] = useState<number | undefined>(undefined);
    const textBoxRefs = useRef<(HTMLDivElement | null)[]>([]);
    const textBoxMobRefs = useRef<(HTMLDivElement | null)[]>([]);
    const highlightRef = useRef<HTMLSpanElement | null>(null);

    useGSAP(() => {
        const tl = gsap.timeline();
        const mm = gsap.matchMedia();
        const totalLength = textBoxRefs.current.length;
        const totalMobLength = textBoxMobRefs.current.length;


        mm.add('(min-width:450px)', () => {
            gsap.set('.expect-left-container', { display: 'flex' })
            gsap.set('.expect-right-container', { display: 'flex' })

            gsap.set('.expect-left-container-mob', { display: 'none' })
            gsap.set('.expect-right-container-mob', { display: 'none' })

            textBoxRefs.current.forEach((card, index) => {
                if (card) {
                    tl.fromTo(
                        card,
                        {
                            y: 100 + index * 100 + 250,
                            opacity: 0,
                            rotation: index % 2 === 0 ? -45 : 45,
                        },
                        {
                            y: index * 70,
                            opacity: 1,
                            rotation: 0,
                            onStart: () => {
                                if (index === 0) setIsAnimationInProgress(true);
                            },
                            onComplete: () => {
                                if (totalLength === index + 1) setIsAnimationInProgress(false);
                            },
                        }
                    );
                }
            });

            if (highlightRef.current) {
                tl.fromTo(
                    highlightRef.current,
                    { backgroundPosition: "0%" },
                    {
                        backgroundPosition: "-100%",
                    }
                );
            }
        })

        mm.add('(max-width:450px)', () => {
            gsap.set('.expect-left-container', { display: 'none' })
            gsap.set('.expect-right-container', { display: 'none' })

            gsap.set('.expect-left-container-mob', { display: 'flex' })
            gsap.set('.expect-right-container-mob', { display: 'flex' })
            gsap.set('.expect-container-mob', { display: 'block' })
            gsap.set('.expect-container', { height: 'fit-content' })

            textBoxMobRefs.current.forEach((card, index) => {
                if (card) {
                    tl.fromTo(
                        card,
                        {
                            y: 100 + index * 100 + 250,
                            rotation: index % 2 === 0 ? -45 : 45,
                        },
                        {
                            y: index * 70,
                            rotation: 0,
                            onStart: () => {
                                if (index === 0) setIsAnimationInProgress(true);
                            },
                            onComplete: () => {
                                if (totalMobLength === index + 1) setIsAnimationInProgress(false);
                            },
                        }
                    );
                }
            });

            tl.fromTo(
                '.expect-highlight-mob',
                { backgroundPosition: "0%" },
                {
                    backgroundPosition: "-100%",
                }
            )
        })
        ScrollTrigger.create({
            animation: tl,
            trigger: ".expect-container",
            toggleActions: "play reverse resume none",
            start: "top top",
            end: "bottom top",
            scrub: 2,
            pin: ".expect-container",
        });
    }, []);

    const handleCard = (cardNo: number) => {
        if (isAnimationInProgress) return;
        if (openCard === cardNo) {
            textBoxRefs.current.forEach((card, index) => {
                if (card) gsap.to(card, { y: index * 70 });
            });
            textBoxMobRefs.current.forEach((card, index) => {
                if (card) gsap.to(card, { y: index * 70 });
            });
            setOpenCard(undefined);
        } else {
            setOpenCard(cardNo);

            textBoxRefs.current.forEach((card, index) => {
                if (card) {
                    if (index <= cardNo) {
                        gsap.to(card, { y: index * 70 });
                    } else {
                        gsap.to(card, { y: index * 70 + 130 });
                    }
                }
            });
            textBoxMobRefs.current.forEach((card, index) => {
                if (card) {
                    if (index <= cardNo) {
                        gsap.to(card, { y: index * 70 });
                    } else {
                        gsap.to(card, { y: index * 70 + 130 });
                    }
                }
            });
        }
    };

    return {
        isAnimationInProgress,
        openCard,
        handleCard,
        textBoxRefs,
        highlightRef,
        textBoxMobRefs
    };
};

export default useExpectAnimation;
