import React from 'react';

export const GuidanceContainer = ({
    icon,
    title,
    description,
    className = 'two-cards',
}: {
    icon?: any;
    title?: string;
    description?: string;
    className?: string;
}) => {
    return (
        <div className={className}>
            <div className="card-icon">{icon}</div>
            <div className="card-title">{title}</div>
            <div className="card-description" dangerouslySetInnerHTML={{ __html: description }}></div>
        </div>
    );
};

export const GuidanceMobContainer = ({
    icon,
    title,
    description,
    className = 'two-cards',
}: {
    icon?: any;
    title?: string;
    description?: string;
    className?: string;
}) => {
    return (
        <div className={className}>
            <img src={icon} className="card-icon" alt="icon" />
            <div className="card-title">{title}</div>
            <div className="card-description" dangerouslySetInnerHTML={{ __html: description }}></div>
        </div>
    );
};
