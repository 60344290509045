import React from 'react';
import './Leadership.scss';
import useLeadershipAnimation from '../../../../hooks/useLeadership';

const LeaderShipSection: React.FC = () => {
    useLeadershipAnimation();

    return (
        <div className="leadership-container">
            <h2 className="features-title">
                <div className="feature-subtitle-first">
                    Don't let <span className="features-span">leadership challenges</span>
                </div>
                <br className="leadership-br" />
                <div className="feature-subtitle-second">hold back your innovation</div>
            </h2>
            <h2 className="features-title-mobile">
                <div className="feature-subtitle-first-mob">
                    Don't let{' '}
                    <span className="features-span-leadership">
                        leadership <br />
                    </span>
                </div>
                <div className="feature-subtitle-second-mob">
                    <span className="features-span-challenges">challenges</span>
                    hold back your innovation
                </div>
            </h2>
        </div>
    );
};

export default LeaderShipSection;
