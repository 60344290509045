import { useRef } from "react";
import gsap from "gsap";
import { Observer } from "gsap/Observer";
import { useGSAP } from "@gsap/react";
const useWindowAnimation = () => {
    const welcomeRef = useRef<HTMLDivElement | null>(null);

    useGSAP(() => {
        gsap.registerPlugin(Observer);
        const mm = gsap.matchMedia();

        const observer = Observer.create({
            target: '.welcome',
            type: "pointer",
            onRight() {
                mm.add("(min-width: 450px)", () => {
                    gsap.to('.hand', { x: '10%', duration: 1 })
                    gsap.to('.bird', { x: '0%', duration: 1 })
                })

                mm.add("(max-width: 450px)", () => {
                    gsap.to('.hand', { x: '10%', duration: 1 })
                    gsap.to('.bird', { x: '-24%', duration: 1 })
                })
            },
            onLeft() {
                mm.add("(min-width: 450px)", () => {
                    gsap.to('.hand', { x: '18%', duration: 1 })
                    gsap.to('.bird', { x: '14%', duration: 1 })
                })
                mm.add("(max-width: 450px)", () => {
                    gsap.to('.hand', { x: '18%', duration: 1 })
                    gsap.to('.bird', { x: '0%', duration: 1 })
                })
            },
            onStop() {
                mm.add("(min-width: 450px)", () => {
                    gsap.to('.hand', { x: '14.5%', duration: 1 })
                    gsap.to('.bird', { x: '7%', duration: 1 })
                })
                mm.add("(max-width: 450px)", () => {
                    gsap.to('.hand', { x: '14.5%', duration: 1 })
                    gsap.to('.bird', { x: '-12%', duration: 1 })
                })
            },
            onMove() { }
        });




        gsap.fromTo(
            ".title-your",
            { y: 110, opacity: 0 },
            { y: 0, opacity: 1, duration: 1, delay: 2 }
        );

        gsap.fromTo(
            ".title-leadership",
            { y: 110, opacity: 0 },
            { y: 0, opacity: 1, duration: 1.2, delay: 2.2 }
        );

        gsap.fromTo(
            ".title-personal",
            { y: 100, opacity: 0 },
            { y: 0, opacity: 1, duration: 1, delay: 2.2 }
        );

        gsap.fromTo(
            ".title-companion",
            { y: 100, opacity: 0 },
            { y: 0, opacity: 1, duration: 1.2, delay: 2.4 }
        );

        gsap.fromTo(
            ".title-subtitle",
            { y: 100, opacity: 0 },
            { y: 0, opacity: 1, duration: 1.2, delay: 2.6 }
        );

        gsap.fromTo(
            ".title-cta",
            { y: 100, opacity: 0 },
            { y: 0, opacity: 1, duration: 1.2, delay: 2.7 }
        );

        gsap.fromTo(
            ".title-subheading",
            { y: -130, opacity: 0 },
            { y: 0, opacity: 1, duration: 1, delay: 2 }
        );

        gsap.fromTo(
            ".title-logo",
            { y: -100, opacity: 0 },
            { y: 0, opacity: 1, duration: 1, delay: 2 }
        );

        gsap.fromTo(
            ".hand",
            { y: '100%', x: '14.5%', opacity: 0 },
            { y: '0%', opacity: 1, duration: 1.3 }
        );

        mm.add("(min-width: 450px)", () => {

            gsap.fromTo(
                ".handpoints",
                { y: '100%', x: '14.2%', opacity: 0 },
                { y: '-11%', opacity: 1, duration: 1.5 }
            );
            gsap.fromTo(
                ".linecontainer",
                { height: '0%', opacity: 0, transformOrigin: 'top', x: '-50%', display: 'block' },
                { height: '100%', opacity: 1, duration: 1.3 }
            );

            gsap.fromTo(
                ".bird",
                { y: "90%", x: '7%', opacity: 0 },
                { y: '-30%', opacity: 1, duration: 1.3 }
            );

            gsap.fromTo(
                ".birdpoints",
                { y: "90%", x: '5%', opacity: 0 },
                { y: '-38%', opacity: 1, duration: 1.5 }
            );

            gsap.fromTo(
                ".birdpointswhite",
                {
                    y: "90%", x: '7%',
                    height: '30vh',
                    opacity: 0
                },
                { y: '-30%', opacity: 1, duration: 1.5 }
            );
        })

        mm.add('(max-width:450px)', () => {
            gsap.to(
                ".headingcontainer",
                { height: '100px' }
            );
            gsap.to(
                ".title-logo",
                { width: '127px' }
            );
            gsap.to(
                ".background-shadow",
                { display: 'none' }
            );
            gsap.to(
                ".background",
                {
                    backgroundImage: 'url(./mobile-landing.webp)'
                }
            );
            gsap.to(
                ".linecontainer",
                { display: 'none' }
            );

            gsap.fromTo(
                ".mobilelinecontainer",
                { height: '0%', opacity: 0, x: '-50%', y: '-50%' },
                { height: '100%', opacity: 1, duration: 1.3 }
            );
            gsap.set(
                ".mobilelinebackground",
                { display: 'block', x: '2%', y: '-1%', scaleX: "1.2", scaleY: '1.1' }
            );
            gsap.to(
                ".content",
                { margin: 0, justifyContent: 'start', paddingTop: '122px' }
            );
            gsap.to(
                ".title",
                { fontSize: '48px', marginBottom: '16px' }
            );
            gsap.to(
                ".title-subtitle",
                { marginBottom: '16px' }
            );
            gsap.to(
                ".handcontainer",
                {
                    x: '10%',
                    y: '12%',
                    width: '97%'
                }
            );
            gsap.to(
                ".handpoints",
                {
                    x: '15%',
                    y: '-5%',
                    opacity: 1, duration: 1.5
                }
            );
            gsap.fromTo(
                ".birdpoints",
                {
                    y: "170%", x: '-16%',
                    height: '18vh',
                    opacity: 0
                },
                {
                    height: '18vh',
                    y: '70%',
                    opacity: 1,
                    duration: 2
                }
            );
            gsap.fromTo(
                ".birdpointswhite",
                {
                    y: "170%", x: '-12%',
                    height: '18vh',
                    opacity: 0
                },
                {
                    y: '75%',
                    opacity: 1,
                    duration: 2
                }
            );
            gsap.fromTo(
                ".bird",
                { y: "170%", x: '-12%', height: '18vh', opacity: 0 },
                { y: '76%', opacity: 1, duration: 1.3 }
            );
        })
        return () => {
            observer.kill();
        };
    }, []);

    return { welcomeRef };
};

export default useWindowAnimation;
