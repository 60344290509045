import React, { useEffect, useState } from 'react';
import './Faqs.scss';
import {
    BlueTwist,
    GradientIcon,
    HeartIcon,
    MissionIcon,
    MobileLock,
    ScienceIcon,
    SecurityIcon,
} from '../../../../assets/img';
import { useGSAP } from '@gsap/react';
import gsap, { ScrollTrigger } from 'gsap/all';
import { qnaData, QnaList } from './QnaBox';
import { getFaqs } from '../../../../utilities/apiService';
import { GuidanceContainer, GuidanceMobContainer } from './GuidanceContainer';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';

const topGuidanceData = [
    {
        icon: <MobileLock />,
        title: '100% data anonymity',
        description:
            'Your leadership journey is personal and fully <br /> anonymized. Your data is non-traceable to your identity.',
        mobileRoute: './lock.png',
    },
    {
        icon: <HeartIcon />,
        title: 'Continuous support',
        description:
            'Get the personalized guidance you need, to grow and <br /> develop on your own terms, anytime and anywhere.',
        mobileRoute: './heart.png',
    },
];

const guidanceData = [
    {
        icon: <SecurityIcon />,
        title: 'Structured but flexible',
        description: 'A solid framework that adapts to different <br /> leadership needs and challenges.',
        mobileRoute: './guard.png',
    },
    {
        icon: <ScienceIcon />,
        title: 'Scientifically founded',
        description:
            'Rooted in science, powered by proven psychological <br /> principles and cutting-edge leadership insights.',
        mobileRoute: './mind.png',
    },
    {
        icon: <MissionIcon />,
        title: 'Mission driven',
        description: 'Designed with a human-centric approach, <br /> emphasizing growth, empowerment, and core values.',
        mobileRoute: './user.png',
    },
];
const FaqsSection = () => {
    const [qnaList, setQnaList] = useState([]);

    useGSAP(() => {
        gsap.registerPlugin(ScrollTrigger);

        const tl = gsap.timeline();
        const tl2 = gsap.timeline();
        const mm = gsap.matchMedia();

        mm.add('(min-width:450px)', () => {
            gsap.set('.faq-container', { padding: '0 160px' });

            gsap.set('.faq-background-mob', { display: 'none' });
            gsap.set('.guidance-container-mob', { display: 'none' });
            gsap.set('.qna-container-mob', { display: 'none' });

            gsap.set('.faq-background', { display: 'block' });
            gsap.set('.guidance-container', { display: 'block' });
            gsap.set('.qna-container', { display: 'grid' });

            tl.fromTo('.faq-background', { opacity: 0 }, { opacity: 1, duration: 3 })
                .fromTo('.faq-twist', { opacity: 0 }, { opacity: 1, duration: 3 }, 0)
                .fromTo('.guidance-title', { opacity: 0 }, { opacity: 1, duration: 3 })
                .fromTo('.guidance-span', { backgroundPosition: '0%' }, { backgroundPosition: '-100%' })
                .fromTo('.guidance-description', { y: 100, opacity: 0 }, { y: 0, opacity: 1, duration: 1 })
                .fromTo('.card-1', { y: 100, opacity: 0 }, { y: 0, opacity: 1, duration: 2 })
                .fromTo('.card-2', { y: 100, opacity: 0 }, { y: 0, opacity: 1, duration: 2.2 })
                .fromTo('.card-3', { y: 100, opacity: 0 }, { y: 0, opacity: 1, duration: 2.4 })
                .fromTo('.card-4', { y: 100, opacity: 0 }, { y: 0, opacity: 1, duration: 2.6 })
                .fromTo('.card-5', { y: 100, opacity: 0 }, { y: 0, opacity: 1, duration: 2.8 });

            tl2.fromTo(
                '.qna-span',
                { backgroundPosition: '0%' },
                {
                    backgroundPosition: '-100%',
                }
            );
            ScrollTrigger.create({
                animation: tl,
                trigger: '.guidance-container',
                toggleActions: 'play reset reverse none',
                start: 'top top',
                end: 'bottom top',
                pin: '.faq-container',
                scrub: true,
            });

            ScrollTrigger.create({
                animation: tl2,
                trigger: '.qna-container',
                toggleActions: 'play reset reverse none',
                start: 'bottom top',
                end: '+=300 top',
                pin: '.faq-container',
                scrub: true,
            });
        });
        mm.add('(max-width:450px)', () => {
            gsap.set('.faq-container', { padding: '0 16px' });

            gsap.set('.faq-background', { display: 'none' });
            gsap.set('.guidance-container', { display: 'none' });
            gsap.set('.qna-container', { display: 'none' });

            gsap.set('.faq-background-mob', { display: 'block' });
            gsap.set('.guidance-container-mob', { display: 'block' });
            gsap.set('.qna-container-mob', { display: 'flex' });

            tl.fromTo('.faq-background-mob', { opacity: 0 }, { opacity: 1, duration: 3 })
                .fromTo('.faq-twist-mob', { opacity: 0 }, { opacity: 1, duration: 3 })
                .fromTo('.guidance-title-mob', { opacity: 0 }, { opacity: 1, duration: 3 })
                .fromTo('.guidance-span-mob', { backgroundPosition: '0%' }, { backgroundPosition: '-100%' })
                .fromTo('.guidance-description-mob', { y: 100, opacity: 0 }, { y: 0, opacity: 1, duration: 1 })
                .fromTo('.card-1', { y: 100, opacity: 0 }, { y: 0, opacity: 1, duration: 2 })
                .fromTo('.card-2', { y: 100, opacity: 0 }, { y: 0, opacity: 1, duration: 2.2 })
                .fromTo('.card-3', { y: 100, opacity: 0 }, { y: 0, opacity: 1, duration: 2.4 })
                .fromTo('.card-4', { y: 100, opacity: 0 }, { y: 0, opacity: 1, duration: 2.6 })
                .fromTo('.card-5', { y: 100, opacity: 0 }, { y: 0, opacity: 1, duration: 2.8 })
                .fromTo(
                    '.qna-span-mob',
                    { backgroundPosition: '0%' },
                    {
                        backgroundPosition: '-100%',
                    }
                );
            ScrollTrigger.create({
                animation: tl,
                trigger: '.guidance-container-mob',
                toggleActions: 'play reset reverse none',
                start: 'top top',
                end: 'bottom top',
                pin: '.faq-container',
                scrub: true,
            });
        });
    }, []);

    useEffect(() => {
        const fetchFaq = async () => {
            try {
                const response = await getFaqs();
                if (response) {
                    setQnaList(response);
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchFaq();
    }, []);

    return (
        <section className="faq-container">
            <img src="./faq.webp" className="faq-background" alt="background" />
            <BlueTwist className="faq-twist" />
            <div className="guidance-container">
                <div className="guidance-top">
                    <div className="guidance-title">
                        <div className="guidance-subtitle-first">
                            Tailored <span className="guidance-span">guidance</span> at
                        </div>
                        <br className="guidance-br" />
                        <div className="guidance-subtitle-second">your fingertips</div>
                    </div>
                    <div className="guidance-description">
                        <div className="guidance-details">
                            Leadership challenges are unique, and so are you. The Leadership Companion delivers personalized
                            advice that evolves with your journey at ETH Zürich. Whether you're navigating team dynamics,
                            making critical decisions, or planning your leadership development, our AI-powered app provides
                            instant, context-aware support.
                        </div>
                    </div>
                </div>
                <div className="guidance-top-cards">
                    {topGuidanceData.map((card, index) => (
                        <GuidanceContainer
                            icon={card.icon}
                            title={card.title}
                            description={card.description}
                            className={`two-cards card-${index + 1} ${index === 1 ? 'card-heart' : ''}`}
                        />
                    ))}
                </div>
                <div className="guidance-bottom-cards">
                    {guidanceData.map((card, index) => (
                        <GuidanceContainer
                            icon={card.icon}
                            title={card.title}
                            description={card.description}
                            className={
                                index === 0
                                    ? `bottom-big-cards two-small-cards card-${index + 1}`
                                    : index === 1
                                    ? `two-small-cards bottom-small-cards card-${index + 1}`
                                    : `two-small-cards bottom-small-cards card-${index + 1}`
                            }
                        />
                    ))}
                </div>
            </div>

            <div className="qna-container">
                <div className="qna-heading">
                    Got <span className="qna-span">questions?</span>
                </div>
                <div className="qna-shadow">
                    <div className="black-gradient">
                        <GradientIcon />
                    </div>
                    <div className="qna-questions">
                        <div className="qna-question-box">
                            <QnaList qnaData={qnaList} />
                        </div>
                    </div>
                </div>
            </div>

            {/* Mobile version */}
            <img src="./faq.webp" className="faq-background-mob" alt="background" />
            <BlueTwist className="faq-twist-mob" />
            <div className="guidance-container-mob">
                <div className="guidance-top-mob">
                    <div className="guidance-title-mob">
                        <div className="guidance-subtitle-first-mob">
                            Tailored <span className="guidance-span-mob">guidance</span> at
                        </div>
                        <br className="guidance-br-mob" />
                        <div className="guidance-subtitle-second-mob">your fingertips</div>
                    </div>
                    <div className="guidance-description-mob">
                        <div className="guidance-details-mob">
                            Leadership challenges are unique, and so are you. The Leadership Companion delivers personalized
                            advice that evolves with your journey at ETH Zürich. Whether you're navigating team dynamics,
                            making critical decisions, or planning your leadership development, our AI-powered app provides
                            instant, context-aware support.
                        </div>
                    </div>
                </div>
                <div className="guidance-cards-mob">
                    <Swiper
                        pagination={{ clickable: true }}
                        spaceBetween={10}
                        slidesPerView={1}
                        modules={[Pagination]}
                        className="guidance-top-cards-mob"
                    >
                        {[...topGuidanceData, ...guidanceData].map((card, index) => (
                            <SwiperSlide key={index}>
                                <GuidanceMobContainer
                                    key={card.title}
                                    icon={card.mobileRoute}
                                    title={card.title}
                                    description={card.description}
                                    className={`mob-card card-${index + 1}`}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>

            <div className="qna-container-mob">
                <div className="qna-heading-mob">
                    Got <span className="qna-span-mob">questions?</span>
                </div>
                <div className="qna-questions-mob">
                    <div className="qna-question-box-mob">
                        <QnaList qnaData={qnaData} />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FaqsSection;
