/* eslint-disable react/style-prop-object */
import React from 'react';
import './MilestoneSetter.scss';
import Button from '../../Button';
import Icon from '../../Icon';
import { MilestoneId } from '../../../types';

interface MilestoneSetterProps {
    milestoneType: MilestoneId;
    milestoneText: string;
    setIsSetMilestone: (value: boolean) => void;
    updateMilestone: (milestone: any) => void;
}

const MilestoneSetter = ({
    milestoneType,
    milestoneText,
    setIsSetMilestone,
    updateMilestone
}: MilestoneSetterProps ) => {
    const [isEditing, setIsEditing] = React.useState(false);
    const [milestoneTextState, setMilestoneTextState] = React.useState(milestoneText);
    const [milestoneTextSaved, setMilestoneTextSaved] = React.useState(milestoneText);

    const handleSave = async () => {
        try {
            updateMilestone({ milestoneType, milestoneText: milestoneTextSaved });
        } catch (error) {
            console.error('Error saving milestone', error);
        }
    };

    const handleCancel = () => {
        setIsEditing(false);
        setMilestoneTextState(milestoneTextSaved);
    };

    const handleEditSave = () => {
        setMilestoneTextSaved(milestoneTextState);
        setIsEditing(false);
    }

    return (
        <div className="milestone-setter-outer-wrapper">
            <div className="milestone-setter-wrapper">
                <div className="milestone-setter">
                    <div className="milestone-setter__left">
                        {isEditing ?
                            <input
                                className="milestone-setter__input"
                                type="text"
                                value={milestoneTextState}
                                onChange={(e) => setMilestoneTextState(e.target.value)}
                            />
                        :
                            <>
                                <div className="milestone-setter__title">
                                    {`Set as ${milestoneType}?`}
                                </div>
                                <div className="milestone-setter__line">
                                    {milestoneTextSaved}
                                </div>
                            </>
                        }
                    </div>
                    <div className="milestone-setter__right">
                        {isEditing ?
                            <>
                                <Button style='secondary' text="Cancel" size="small" onClick={handleCancel}/>
                                <Button
                                    text="Save"
                                    icon={<Icon type="check" className="check-icon"/>}
                                    size="small"
                                    onClick={handleEditSave}
                                />
                            </>
                        :
                            <>
                                <Button style='secondary' text="Cancel" size="small" onClick={() => setIsSetMilestone(false)}/>
                                <Button style='secondary' text="Edit" size="small" onClick={() => setIsEditing(true)}/>
                                <Button
                                    text={`Set as my ${milestoneType}`}
                                    icon={<Icon type="check" className="check-icon"/>}
                                    size="small"
                                    onClick={handleSave}
                                />
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MilestoneSetter;
