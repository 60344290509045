import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import './Home.scss';
import { useAppSelector } from '../../store';
import useWebSocket from "./../../hooks/useWebSocket";
import LeftContainer from "../LeftContainer";
import LinksContainer from "../LinksContainer";
import RightContainer from "../RightContainer";
import ChatContainer from "../ChatContainer";
import ControlRow from "../../components/ControlRow";
import SpeechModal from "../../components/SpeechModal";
import FileUploadModal from "../../components/FileUploadModal";
import SessionsContainer from '../SessionsContainer';
import { sessionActions } from '../../actions';

const Home = () => {
    const queryParams = useMemo(() => {
        return new URLSearchParams(window.location.search);
    }, []);
    let port = parseInt(queryParams.get("port"), 10);
    const initialMessage = useAppSelector(state => state.session.message);
    const sessions = useAppSelector(state => state.session.sessions);
    const auth = useAppSelector(state => state.auth);
    const activeSession = useAppSelector(state => state.session.activeSession);
    const [initialMessageSent, setInitialMessageSent] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Determine if we're running locally
    const isLocalhost =
        window.location.hostname === "localhost" ||
        window.location.hostname === "127.0.0.1";

    // If the port is NaN or not specified, set it to 443 for wss:// connections
    if (isNaN(port) && !isLocalhost) {
        port = 443; // Default to port 443 for secure connections
    }
    // Use ws:// for localhost, and wss:// for anything else
    const wsUrl = isLocalhost
        ? `wss://localhost/ws/`  // WebSocket for local development
        : `wss://${window.location.hostname}/ws/`;  // Secure WebSocket for production


    const {
        messages,
        sendMessage,
        objective,
        problem,
        goal,
        solution,
        action,
        sources,
        links,
        resetChat,
        chatId,
        switchSession,
        isAuthenticated,
    } = useWebSocket(wsUrl, auth.accessToken);

    const [isModalOpen, setModalOpen] = useState(false);

    const speechMode = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const [isFileUploadModalOpen, setFileUploadModalOpen] = useState(false);

    const openFileUploadModal = () => {
        setFileUploadModalOpen(true);
    };

    const closeFileUploadModal = () => {
        setFileUploadModalOpen(false);
    };

    const handleFileUpload = (files) => {
        console.log("Files uploaded:", files);
        setFileUploadModalOpen(false);
    };

    useEffect(() => {
        if (!initialMessageSent && initialMessage && isAuthenticated) {
            localStorage.setItem('activeSessionId', "");
            sessionActions.setActiveSession(null)(dispatch);
            sendMessage(initialMessage, false, true);
            setInitialMessageSent(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialMessageSent, initialMessage, dispatch, isAuthenticated]);

    useEffect(() => {
        if (chatId && !activeSession?.hash_id && initialMessageSent) {
            localStorage.setItem('activeSessionId', chatId);
        }
    }, [chatId, activeSession?.hash_id, initialMessageSent]);

    useEffect(() => {
        if (!activeSession?.hash_id && sessions && sessions.length > 0 && !initialMessage) {
            const activeSessionId = localStorage.getItem('activeSessionId');
            if (activeSessionId) {
                const session = sessions.find(session => session.hash_id === activeSessionId);
                if (session) {
                    sessionActions.setActiveSession(session)(dispatch);
                }
            }
        }
    }, [sessions, activeSession, initialMessage, dispatch]);

    return (
        <div className="App">
            <Link to="/login">Login</Link>
            <div id="state_machine-container">
                <SessionsContainer sessions={sessions} switchSession={switchSession}/>
                <LeftContainer objective={objective} problem={problem} goal={goal} solution={solution} action={action}/>
                <LinksContainer links={links}/>
                <RightContainer sources={sources}/>
            </div>
            <ChatContainer
                messages={messages}
                show={isModalOpen}
                sendMessage={sendMessage}
            />
            <ControlRow
                resetChat={resetChat}
                speechMode={speechMode}
                uploadFile={openFileUploadModal} // Update to open the file upload modal
                openSession={() => {
                    if (activeSession?.hash_id) {
                        const session = sessions.find(session => session.hash_id === activeSession.hash_id);
                        if (session) {
                            navigate(`/session/${activeSession.hash_id}`);
                        }
                    }
                }}
            />
            <SpeechModal
                show={isModalOpen}
                onClose={closeModal}
                messages={messages}
                sendMessage={sendMessage}
                chatId={chatId}
            >
                <div className="modal-header">Speech Mode</div>
                <div className="modal-body">
                    <p>Speech mode is now active.</p>
                </div>
            </SpeechModal>
            <FileUploadModal
                show={isFileUploadModalOpen}
                onClose={closeFileUploadModal}
                onFileUpload={handleFileUpload}
            />
        </div>
    );
}

export default Home;
