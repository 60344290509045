// Welcome.tsx
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import './Welcome.scss';
import useWindowAnimation from '../../../../hooks/useWindowAnimation';
import {
    EthPattern,
    Hand,
    HandNoise,
    MainLogo,
    MobStraightLine,
    OrigamiBird,
    OrigamiBirdNoise,
    OrigamiBirdNoiseWhite,
    Shadow,
    StraightLine,
} from '../../../../assets/img';
import { sessionActions } from '../../../../actions';
import { history } from '../../../../store';
import { useDispatch } from 'react-redux';

const Welcome = () => {
    const { welcomeRef } = useWindowAnimation();
    const dispatch = useDispatch();

    const startNewSession = () => {
        localStorage.setItem('activeSessionId', '');
        sessionActions.sendMessage(null)(dispatch);
        history.push('/session');
    };

    return (
        <section className="welcome" ref={welcomeRef}>
            <div className="background" />
            <EthPattern className="background-lines" />
            <Shadow className="background-shadow" />
            <div className="linecontainer">
                <StraightLine className="linebackground" />
            </div>
            <div className="mobilelinecontainer">
                <MobStraightLine className="mobilelinebackground" />
            </div>
            <div className="handcontainer">
                <Hand className="hand" />
            </div>
            <div className="handcontainer">
                <HandNoise className="handpoints" />
            </div>
            <div className="birdcontainer">
                <OrigamiBird className="bird" style={{ width: '90%' }} />
            </div>
            <div className="birdcontainer">
                <OrigamiBirdNoise className="birdpoints" style={{ width: '90%' }} />
            </div>
            <div className="birdcontainer">
                <OrigamiBirdNoiseWhite className="birdpointswhite" style={{ width: '90%' }} />
            </div>
            <div className="headingcontainer" draggable="false">
                <div className="heading">
                    <div className="title-subheading">LEADERSHIP REIMAGINED AT</div>
                    <MainLogo className="title-logo" />
                </div>
            </div>
            <div className="content" draggable="false">
                <div className="title">
                    <span className="title-your">Your</span>
                    <span className="title-personal">personal</span>
                    <span className="title-leadership">leadership</span>
                    <span className="title-companion">companion</span>
                </div>

                <p className="title-subtitle">
                    Lead with confidence and ease, <br /> knowing you’re always supported.
                </p>

                <button className="title-cta" onClick={() => startNewSession()}>
                    <FaPlus size={12} /> <div>Start new session now</div>
                </button>
            </div>
        </section>
    );
};

export default Welcome;
