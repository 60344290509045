import { useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useGSAP } from "@gsap/react";

const useCompanionAnimation = () => {
    const companionFirstRef = useRef<HTMLDivElement | null>(null);
    const companionSecondRef = useRef<HTMLDivElement | null>(null);
    const companionThirdRef = useRef<HTMLDivElement | null>(null);
    const companionSpanRef = useRef<HTMLSpanElement | null>(null);
    const companionTabletRef = useRef<HTMLImageElement | null>(null);

    useGSAP(() => {
        const tl = gsap.timeline();
        const tl2 = gsap.timeline({ paused: true });
        const mm = gsap.matchMedia();

        mm.add('(min-width:450px)', () => {
            gsap.set('.companion-bird-noise-mob', { display: 'none' })
            gsap.set('.companion-bird-noise', { display: 'block' })
            gsap.set(companionFirstRef.current, { display: 'block' })
            gsap.set(
                '.companion-title-mob',
                { display: 'none' },
            )
            gsap.set(
                '.companion-title',
                { display: 'block' },
            )

            gsap.set(
                '.companion-tablet-mob',
                { display: 'none' },
            )
            gsap.set(
                '.companion-tablet',
                { display: 'block' },
            )

            tl.fromTo(
                companionFirstRef.current,
                { y: 300, opacity: 0 },
                { y: 0, opacity: 1, duration: 6 },
                0
            )
                .fromTo(
                    companionSecondRef.current,
                    { y: 300, opacity: 0 },
                    { y: 0, opacity: 1, duration: 6 },
                    0
                )
                .fromTo(
                    companionThirdRef.current,
                    { y: 300, opacity: 0 },
                    { y: 0, opacity: 1, duration: 6 },
                    0
                )
                .fromTo(
                    companionSpanRef.current,
                    { backgroundPosition: "0%" },
                    { backgroundPosition: "-100%", duration: 4 }
                )
                .fromTo(
                    companionTabletRef.current,
                    { y: 100, opacity: 0 },
                    { y: 80, opacity: 1, duration: 4 },
                    0
                );



            tl2.to(companionTabletRef.current, {
                width: '30%'
            })
        })

        mm.add('(max-width:450px)', () => {
            gsap.set(
                '.companion-title-mob',
                { display: 'block' },
            )
            gsap.set(
                '.companion-title',
                { display: 'none' },
            )
            gsap.set(
                '.companion-tablet-mob',
                { display: 'block', margin: '0' },
            )
            gsap.set(
                '.companion-tablet',
                { display: 'none' },
            )
            gsap.set('.companion-bird-noise-mob', { display: 'block' })
            gsap.set('.companion-bird-noise', { display: 'none' })
            gsap.set('.companion-container', { gap: '50px' })
            gsap.set('.tab-container', { display: 'flex' })


            tl.fromTo(
                '.companion-title-mob',
                { y: 300, opacity: 0, display: 'block' },
                { y: 0, opacity: 1, duration: 6 }
            ).fromTo(
                '.companion-span-mob',
                { backgroundPosition: "0%" },
                { backgroundPosition: "-100%", duration: 4 }
            ).fromTo(
                '.companion-subtitle-first-mob',
                { y: 300, opacity: 0 },
                { y: 0, opacity: 1, duration: 6 },
                0
            )
                .fromTo(
                    '.companion-subtitle-second-mob',
                    { y: 300, opacity: 0 },
                    { y: 0, opacity: 1, duration: 6 },
                    0
                )
                .fromTo(
                    '.companion-subtitle-second-mob',
                    { y: 300, opacity: 0 },
                    { y: 0, opacity: 1, duration: 6 },
                    0
                )
                .fromTo(
                    companionSpanRef.current,
                    { backgroundPosition: "0%" },
                    { backgroundPosition: "-100%", duration: 4 }
                )
                .fromTo(
                    '.companion-bird-',
                    { y: 100, opacity: 0 },
                    { y: 80, opacity: 1, duration: 4 },
                    0
                );
        })

        ScrollTrigger.create({
            animation: tl,
            trigger: ".companion-container",
            toggleActions: "play reverse resume none",
            start: "top top",
            end: "bottom top",
            pin: true,
            scrub: true,
            onLeave: () => {
                tl2.play()
            },
            onEnterBack: () => {
                tl2.reverse()
            }
        });
    }, []);

    return {
        companionFirstRef,
        companionSecondRef,
        companionThirdRef,
        companionSpanRef,
        companionTabletRef,
    };
};

export default useCompanionAnimation;
